import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Wrapper from '../components/layout/wrapper'
import Img from "gatsby-image"
import Style from './index.module.scss'

const IndexPage = ({ data }) => {

  return (
    <Layout>
      <SEO title="Home" />
        <section className={Style.homeHero}>
            <div className={Style.cols}>
              <div className={Style.colLeft}>
                <div className={Style.textContainer}>
                <h1 className={Style.headline}>{data.prismicHome.data.hero_headline.text}</h1>
                <p className={Style.subtitle}>{data.prismicHome.data.hero_subtitle.text}</p>
                <Link className={Style.searchButton} to="/locations">Find a home</Link>
                </div>
              </div>
              <div className={Style.colRight}>
              <Img fluid={ data.prismicHome.data.hero_image.localFile.childImageSharp.fluid } />
              </div>
            </div>
        </section>
        {/* <section className={Style.locationsHero}>
          <Wrapper>
            <h2>
              Second 
            </h2>
          </Wrapper>
        </section> */}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query HomeQuery {
  prismicHome {
    data {
      hero_headline {
        text
      }
      hero_subtitle {
        text
      }
      hero_image {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
`